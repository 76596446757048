import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from './../_services/authentication.service'

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    formGroup: FormGroup;

    constructor(
        private router: Router,
        private http: HttpClient,
        private authenticationService: AuthenticationService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.formGroup = new FormGroup({
            "userName": new FormControl("", [
                Validators.required,
                Validators.minLength(1)
            ]),
            "userPass": new FormControl("", [
                Validators.required,
                Validators.minLength(1)
            ])
        });
    }

    loginProcess() {
        if (this.formGroup.valid) {
            this.authenticationService.login(
                this.formGroup.get("userName").value,
                this.formGroup.get("userPass").value
            )
            .subscribe(
                data => {
                    if (data) {
                        this.router.navigate(['/makeQuery']);
                    }
                },
                error => {
                    console.log(error);
                    alert("Post Request error, Status:" + error.status);
                }
            );
        }
    }
}
