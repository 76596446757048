<mat-toolbar color="primary">
    <button mat-icon-button class="fix-button" title=" Visualizza/Nascondi editor delle query... " (click)="drawer.toggle()">
        <mat-icon>vertical_split</mat-icon>
    </button>
    <span class="example-spacer"></span>
    <img src="../../assets/footer_logo.png" class="tb-logo" /><span>SPARQL Query Manager</span>
    <span class="example-spacer"></span>
    <button mat-icon-button class="fix-button" title=" Disconnetti utente... " (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
    </button>
</mat-toolbar>
<!-- Creates a layout with a left-positioned sidenav and explicit content. -->
<mat-sidenav-container class="sidenav-container" autosize disableClose>
    <mat-sidenav #drawer class="sidenav-left" mode="side" opened disableClose>

        <form fxLayoutAlign="center stretch" fxLayout="column" class="make-form" [formGroup]="formGroup"
            (ngSubmit)="queryProcess()">
            <mat-form-field appearance="fill">
                <mat-label>Select Dataset</mat-label>
                <mat-select formControlName="selectDataset">
                    <mat-option [value]="dataset" *ngFor="let dataset of datasets">{{sanitizeLabel(dataset)}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Insert Query</mat-label>
                <textarea #mtfarea matInput placeholder="Example:

SELECT *
WHERE {
    ?entity clvapit:hasCountry ?Country .
    ?Country rdfs:label ?CountryLabel .
    ?entity clvapit:hasRegion ?Region .
    ?Region rdfs:label ?RegionLabel .
    ?entity clvapit:hasProvince ?Province .
    ?Province rdfs:label ?ProvinceLabel .
    ?entity clvapit:hasCity ?City .
    ?City rdfs:label ?CityLabel .
}" formControlName="queryEditor"></textarea>
            </mat-form-field>
            <button mat-raised-button type="submit" class="mt-1" color="primary"> Execute Query </button>
        </form>

    </mat-sidenav>
    <mat-sidenav-content>

        <ng-container [ngSwitch]="pageHaveStatus">
            <ng-container *ngSwitchCase="'loading'">
                <div fxLayoutAlign="center center" fxFlexFill>
                    <mat-spinner mode="indeterminate" diameter="50"></mat-spinner>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'results_ready'">
                <div class="table-res-container">
                    <table mat-table [dataSource]="displayedColumns" class="table-min-width">
                        <ng-container *ngFor="let column of columnsToRender;" [matColumnDef]="column">
                            <th mat-header-cell *matHeaderCellDef> {{ column }} </th>
                            <td mat-cell *matCellDef="let row"> {{ row[column] }} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="columnsToRender"></tr>
                        <tr mat-row *matRowDef="let row; columns: columnsToRender"></tr>
                    </table>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'results_error'">
                <div class="welcome">
                    Impossibile completare l'interrogazione al database!<br />
                    Verificare la correttezza della sintassi o eventuali problemi di rete.
                </div>
            </ng-container>

            <ng-container *ngSwitchDefault>
                <div class="welcome">
                    Benvenuto, {{this.currentUser.user_profile.firstName}} {{this.currentUser.user_profile.lastName}}.<br />
                    Per cominciare seleziona uno dei <b>datasets</b> disponibili e crea la tua prima <b>query</b>!
                </div>
            </ng-container>
        </ng-container>

    </mat-sidenav-content>
</mat-sidenav-container>
