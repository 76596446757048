<div fxLayoutAlign="center center" fxFlexFill class="main-div">
    <mat-card>
        <mat-toolbar color="primary"><img src="../../assets/footer_logo.png" class="tb-logo" /> Login Page</mat-toolbar>
        <form fxLayoutAlign="stretch" fxLayout="column" [formGroup]="formGroup" (ngSubmit)="loginProcess()">
            <mat-form-field class="mt-3">
                <mat-label>Username</mat-label>
                <input matInput type="email" placeholder="Example: mrossi@sysmanagement.it" formControlName="userName" ngModel [email]="true">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Password</mat-label>
                <input matInput type="password" placeholder="" formControlName="userPass">
            </mat-form-field>
            <button mat-raised-button type="submit" class="mt-3" color="primary"> Login </button>
        </form>
    </mat-card>
</div>
