import { Renderer2, ElementRef, ViewChild, HostListener, Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getAllDatasets, sendSparQLQuery } from 'src/environments/environment';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from './../_services/authentication.service';
import { User } from './../_models/user';

@Component({
    selector: 'app-make-query',
    templateUrl: './make-query.component.html',
    styleUrls: ['./make-query.component.css']
})
export class MakeQueryComponent implements OnInit {
    formGroup: FormGroup;
    value: Object;
    innerWidth: any;
    innerHeight: any;
    displayedColumns: any[] = [];
    columnsToRender: any[] = [];
    currentUser: User;
    datasets: any[] = [];
    pageHaveStatus: string = "welcome";

    private p_getAllDatasets  = getAllDatasets;
    private p_sendSparQLQuery = sendSparQLQuery;

    @ViewChild("mtfarea") textAreaView: ElementRef;
    constructor(
        private renderer: Renderer2,
        private http: HttpClient,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        setInterval(this.verify.bind(this), 15000);
        this.verify();
    }

    verify() {
        const currentUser = this.authenticationService.currentUserValue;
        if (!(currentUser)) {
            return;
        }
        this.authenticationService.verify().subscribe(isLoggedIn => {
            if (!isLoggedIn) {
                this.logout();
            }
        },
        error => {
            console.log("VerifyError:  ", error);
            if (error.status == 401) {
                this.authenticationService.reset();
                this.router.navigate(['/login']);
            }
        });
    }

    logout() {
        this.authenticationService.logout().subscribe(isLoggedOut => {
            if (isLoggedOut) {
                this.router.navigate(['/login']);
            }
        },
        error => {
            console.log("LogoutError:  ", error);
            if (error.status == 401) {
                this.authenticationService.reset();
                this.router.navigate(['/login']);
            }
        });
    }

    ngOnInit(): void {
        this.innerWidth = window.innerWidth;
        this.innerHeight = window.innerHeight;
        this.initForm();
    }

    ngAfterContentInit(): void {
        setTimeout(() => {
            var height = `${this.innerHeight - 268}px`;
            this.renderer.setStyle(this.textAreaView.nativeElement, "height", height);
            this.renderer.setStyle(this.textAreaView.nativeElement, "min-height", height);
            this.renderer.setStyle(this.textAreaView.nativeElement, "max-height", height);
        }, 500);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
        this.innerHeight = window.innerHeight;

        var height = `${this.innerHeight - 268}px`;
        this.renderer.setStyle(this.textAreaView.nativeElement, "height", height);
        this.renderer.setStyle(this.textAreaView.nativeElement, "min-height", height);
        this.renderer.setStyle(this.textAreaView.nativeElement, "max-height", height);
    }

    initForm() {
        this.formGroup = new FormGroup({
            "selectDataset": new FormControl("", [
                Validators.required,
                Validators.minLength(1)
            ]),
            "queryEditor": new FormControl("", [
                Validators.required,
                Validators.minLength(1)
            ])
        });
        this.p_getAllDatasets(this.http).subscribe(
            data => {
                if (data && Array.isArray(data.datasets)) {
                    this.datasets = data.datasets;
                }
                else this.datasets = [];
            },
            error => {
                this.datasets = [];
            }
        );
    }

    queryProcess() {
        if (this.formGroup.valid) {
            this.pageHaveStatus = "loading";
            this.p_sendSparQLQuery(
                this.http,
                {
                    "query":   this.formGroup.get("queryEditor").value,
                    "dataset": this.formGroup.get("selectDataset").value
                }
            ).subscribe(
                result => {
                    console.log("POST Request is successful ", result);
                    this.value = result;
                    this.displayedColumns = result.Results;
                    this.columnsToRender = result.resultVars;
                    this.pageHaveStatus = "results_ready";
                },
                error => {
                    this.displayedColumns = [];
                    this.columnsToRender  = [];
                    this.pageHaveStatus = "results_error";
                }
            );
        }
    }

    sanitizeLabel(text: string) {
        if (text) {
            return text.replace(/^\//, '');
        }
        else return null;
    }
}
