// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

export const environment = {
  production: false
};

export const baseWSO2Url   = 'http://192.168.70.144:8085';
export const baseEdubbaUrl = 'https://v2.api.edubba.databencart.com';
//export const authBasicWSO2 = 'Basic VG5RMktsX0luSlNtZXNiQjlmZXdvMFpvX2tFYTpKaThzVUdhQ0prbGwwbG1hSkUyallFc1daNkVh';

//const authBasicToken: string = `${authBasicWSO2}`;
//const urlGetToken: string    = `${baseWSO2Url}/token/?grant_type=client_credentials`;
const urlGetDatasets: string = `${baseWSO2Url}/lod/getDataset`;
const urlSendQuery: string   = `${baseWSO2Url}/lod/doSparqlQuery`;

export function getAllDatasets(http): Observable<any> {
    let headers = new HttpHeaders({
        'Accept': '*/*',
        'Content-Type': 'application/json'
    });
    return http.get(
        urlGetDatasets,
        { headers }
    ).pipe(map(
        subResults => {
            return subResults;
        }
    ));
}

export function sendSparQLQuery(http, query): Observable<any> {
    let headers = new HttpHeaders({
        'Accept': '*/*',
        'Content-Type': 'application/json'
    });
    return http.post(
        urlSendQuery,
        query,
        { headers }
    ).pipe(map(
        subResults => {
            return subResults;
        }
    ));
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
