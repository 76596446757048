import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { baseEdubbaUrl } from 'src/environments/environment';
import sha1Hasher from 'sha1';
import { User } from './../_models/user';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    public currentUser: Observable<User>;
    private currentUserSubject: BehaviorSubject<User>;

    private urlAuthCheck: string  = `${baseEdubbaUrl}/auth`;
    private urlAuthLogin: string  = `${baseEdubbaUrl}/auth/login`;
    private urlAuthLogout: string = `${baseEdubbaUrl}/auth/logout`;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public login(username: string, password: string) {
        let loginBody = {
            "user_name": username,
            "user_hash": sha1Hasher(username + ":" + password)
        };
        return this.http.post<any>(this.urlAuthLogin, loginBody)
        .pipe(map(result => {
            // login successful if there's a jwt token in the response
            if (result                                       &&
                result.rowCount > 0                          &&
                result.rows[0]["session_result"]             &&
                result.rows[0]["session_action"] == "create" &&
                result.rows[0]["session_data"] != null) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(result.rows[0]["session_data"]));
                this.currentUserSubject.next(result.rows[0]["session_data"]);
                return result.rows[0]["session_data"];
            }
            else {
                return null;
            }
        }));
    }

    public verify() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': "Basic " + btoa(
                this.currentUserSubject.value.user_uuid + ":" +
                this.currentUserSubject.value.user_ssid
            )
        });
        return this.http.get<any>(this.urlAuthCheck, { headers })
        .pipe(map(result => {
            // login successful if there's a jwt token in the response
            if (result                           &&
                result.rowCount > 0              &&
                result.rows[0]["session_result"] &&
                result.rows[0]["session_action"] == "verify") {
                return true;
            }
            else {
                return false;
            }
        }));
    }

    public logout() {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': "Basic " + btoa(
                this.currentUserSubject.value.user_uuid + ":" +
                this.currentUserSubject.value.user_ssid
            )
        });
        return this.http.post<any>(this.urlAuthLogout, {}, { headers })
        .pipe(map(result => {
            // login successful if there's a jwt token in the response
            if (result                           &&
                result.rowCount > 0              &&
                result.rows[0]["session_result"] &&
                result.rows[0]["session_action"] == "destroy") {
                this.reset();
                return true;
            }
            else {
                return false;
            }
        }));
    }

    reset() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}
